import styled from 'styled-components'
// @ts-ignore
import * as bg from '../../images/2021_05_JM_Blurred_Arcade.svg';

const HeaderStyle = styled.div`
  width: 100%;

  .header {
    background-color: var(--darkBlue);
    
    &__toggle {
      display: none;
    }

    &__title {
      display: inline-block;
      line-height: 3rem;
      font-size: 1rem;
      padding: 0 1rem;
      text-transform: capitalize;
    }

    &__content {
      display: inline-block;
      margin-left: auto;
    }
  }

  .logo-container {
    background-color: var(--darkBlue);
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 150%;
    padding: 4.5rem 0 0;
    text-align: center;

    img.index-logo {
      text-align: center;
      display: block;
      height: 6rem;
      width: auto;
      margin: auto;
    }
  }

  .title-container {
    display: inline-block;
    margin: 2rem auto 1rem;
    border-top: 1px solid var(--fadedWhite);
    padding: 2rem 2rem 0;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) { 
    .logo-container {
      padding: 3rem 0 0;

      img.index-logo {
        height: 3rem;
        width: auto;
      }
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    .logo-container img.index-logo {
      height: 4rem;
      width: auto;
    }
  }
`;

export default HeaderStyle