import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Chip, Typography } from '@material-ui/core';
import GeneralPostCardStyle from './general-post-card-style';
import { Link } from 'gatsby';

export interface GeneralPostCardProps {
  post: any;
}

const GeneralPostCard: FunctionComponent<GeneralPostCardProps> = ({
  post,
}: GeneralPostCardProps) => {
  const [ categories, setCategories ] = useState<any>([]);
  const [ formattedDate, setFormattedDate ] = useState<any>([]);

  useEffect(() => {
    if (post.date) {
      const date = new Date(post.date);
      setFormattedDate(date.toLocaleDateString());
    }
    if (post.categories.nodes) {
      setCategories(post.categories.nodes);
    }
  }, [post])

  const categoryChips = [];
  for (const [index, value] of categories.entries()) {
    categoryChips.push(
      <Chip color="secondary" key={index} label={value.name} size="small" />
    )
  };

  return (
    <GeneralPostCardStyle>
      <Link to={`/${post.Posts.postType.toLowerCase()}/post/?id=${post.databaseId}`} state={{ id: post.databaseId }}>
        <Card className="card">
          {post.featuredImage ? (
            <CardMedia
              className="card-image"
              image={post.featuredImage.node.sourceUrl}
              title={post.featuredImage.node.altText}
            />
          ) : null }
          <div className="mt-3 pt-3 pr-3 pb-3">
            <Typography gutterBottom variant="h5" component="h2" className="title">
              {post.title}
            </Typography>
          </div>
          <CardContent>
            <Typography variant="body2" className="d-inline-block p-2 caption mb-3 text-uppercase">
              {post.Posts.postType}
            </Typography>
            <Typography variant="subtitle2" className="pb-3" gutterBottom>
              {formattedDate}
            </Typography>
            {categoryChips ? (
              categoryChips
            ) : null}
          </CardContent>
        </Card>
      </Link>
    </GeneralPostCardStyle>
  );
};

export default GeneralPostCard
