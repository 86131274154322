import React, { FunctionComponent, ReactNode } from 'react';
import { IoIosMenu } from 'react-icons/io';
import { IconButton, Card } from '@material-ui/core';
import HeaderStyle from './header-style';
// @ts-ignore
import * as logo from '../../images/jm_logo.svg';

export interface HeaderProps {
  title: ReactNode;
  onSidebarToggle?: () => void;
  isOpen?: boolean;
  children?: ReactNode;
}

const Header: FunctionComponent<HeaderProps> = ({
  title,
  onSidebarToggle,
  isOpen,
  children,
}: HeaderProps) => (
  <HeaderStyle>
    <Card className="header p-2 shadow-2">
      {onSidebarToggle && !isOpen && isOpen !== undefined && (
        <IconButton
          aria-label="Open Menu"
          className="header__toggle d-inline-block d-sm-none"
          onClick={onSidebarToggle}
        >
          <IoIosMenu size="24" />
        </IconButton>
      )}
      <div>
        <div className="logo-container text-center">
          <img className="index-logo" src={logo} alt="Joystick Maverick" height="72" />
          <div className="title-container">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </Card>
  </HeaderStyle>
)

export default Header
